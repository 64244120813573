/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #00b0e0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.Editor {
  word-wrap: break-word !important;
  overflow-wrap: break-word;
  white-space: normal !important;
}

.transcript.interim {
  color: darkgray;
}

.transcript[transcript-options] {
  border-bottom: 3px solid #00b0e0;
  margin-bottom: -3px;
  position: relative;
}

.transcript[transcript-options][ctrl-hotkey]::after {
  content: attr(ctrl-hotkey);
  display: block;
  width: 100%;
  height: 1rem;
  background-color: darkgrey;
  position: absolute;
  top: calc(100% / 2 - 0.5rem);
  border-radius: 0.4rem;
  font-size: 0.6rem;
  text-align: center;
}

/* Jerry: belongs to insert script */
.transcript-menu-wrapper {
  position: fixed;
  min-width: 8rem;
  top: 0;
  left: 0;
  display: inline-block;
}

.transcript-menu {
  min-width: 8rem;
  background-color: white;
  border-radius: 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  margin-top: 0.7rem;
  z-index: 10000;
  max-height: calc(2.35rem * 9);
  overflow-y: auto;
}

/* width */
.transcript-menu::-webkit-scrollbar {
  width: 0.1rem;
}

/* Track */
.transcript-menu::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px darkgrey; */
  border-radius: 0;
}

/* Handle */
.transcript-menu::-webkit-scrollbar-thumb {
  background: darkgray;
}

.transcript-menu-wrapper:not(.show) {
  display: none !important;
}

.transcript-menu-padding {
  background-color: transparent !important;
  padding: 0 !important;
  padding-top: 1px;
  cursor: default !important;
  display: none;
}

.transcript-menu.page-mode .transcript-menu-padding {
  display: block !important;
}

.transcript-menu-padding:nth-child(9n + 1) {
  height: 0;
}

.transcript-menu-padding:nth-child(9n + 2) {
  height: calc(2.35rem * 8);
}

.transcript-menu-padding:nth-child(9n + 3) {
  height: calc(2.35rem * 7);
}

.transcript-menu-padding:nth-child(9n + 4) {
  height: calc(2.35rem * 6);
}

.transcript-menu-padding:nth-child(9n + 5) {
  height: calc(2.35rem * 5);
}

.transcript-menu-padding:nth-child(9n + 6) {
  height: calc(2.35rem * 4);
}

.transcript-menu-padding:nth-child(9n + 7) {
  height: calc(2.35rem * 3);
}

.transcript-menu-padding:nth-child(9n + 8) {
  height: calc(2.35rem * 2);
}

.transcript-menu-padding:nth-child(9n + 9) {
  height: calc(2.35rem * 1);
}

.transcript-menu > div {
  padding: 0.5em;
  height: 1.35rem;
}

.transcript-menu > div > span.hotkey-number {
  display: inline-block;
  /* margin-right: 0.5rem; */
  margin-left: 0.2rem;
  width: 1rem;
}

.transcript-menu > div:hover {
  /* background-color: #00b0e0; */
  /* color: white; */
  background: #f5f5f5;
  cursor: pointer;
}

.transcript-menu > div.selected {
  background: #00b0e0;
}

.transcript-menu > div:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.transcript-menu > div:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
